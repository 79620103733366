export const ADD_PROJECTS = "ADD_PROJECTS";
export const PROJECTS_FAILED = "PROJECTS_FAILED";
export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const TOGGLE = "TOGGLE";
export const LOADED = "LOADED";
export const UNMOUNT = "UNMOUNT";
export const SENDMESSAGE = "SENDMESSAGE";
export const SHOWNAV = "SHOWNAV";
export const HIDENAV = "HIDENAV";
export const EYEMOVE = "EYEMOVE";
export const TOGGLECOLLAPSE = "TOGGLECOLLAPSE";
export const LOADEDIMG = "LOADEDIMG";
